import * as React from "react"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header from '../components/header'
import StickyNav from '../components/stickynav'
import Flush from '../components/flush'
import CardCol from '../components/card'
import Footer from '../components/footer'

import '../components/font.css';
import '../components/stylesheet.css';

import mockup from "../images/5678-top-img.png"
import sketch from "../images/sketch-bdblack.svg"
import illustrator from "../images/ai-bdblack.svg"
import zeplin from "../images/zeplin-bdblack.svg"

import background1 from "../images/5678/background-donut-chart.svg"
import background2 from "../images/5678/background-process.svg"
import background3 from "../images/5678/background-zoom.png"

import persona1 from "../images/5678/persona-jj.svg"
import persona2 from "../images/5678/persona-liz.svg"

import goal1 from "../images/5678/goal-easy.svg"
import goal2 from "../images/5678/goal-best.svg"
import goal3 from "../images/5678/goal-fun.svg"
import goalFlow from "../images/5678/goal-flow.svg"

import ws1 from "../images/5678/wireframe_storyboard_1.jpg"
import ws2 from "../images/5678/wireframe_storyboard_2.jpg"
import ut1 from "../images/5678/user-test-1.jpg"
import ut2 from "../images/5678/user-test-2.jpg"


import feature1 from "../images/5678/feature-1.png"
import feature2 from "../images/5678/feature-2.png"
import feature3 from "../images/5678/feature-3.png"
import feature4 from "../images/5678/feature-4.png"
import feature5 from "../images/5678/feature-5.png"

import card5678 from "../images/5678-card-img.png"
import card1mws from "../images/1mws-card-img.png"
import card1mhd from "../images/homedance-card-img.png"

const itemLink = [
  {text: "소개", destination: "#top",},
  {text: "기획 배경", destination: "#background",},
  {text: "상세 기획", destination: "#process",},
  {text: "기능", destination: "#feature",},
  {text: "후기", destination: "#review",},
]

const sftwrList = [
  {name: "sketch", src: sketch},
  {name: "illustrator", src: illustrator},
  {name: "zeplin", src: zeplin}
]

// markup
const Project1 = () => {
  return (
    <main lang="ko">
    <title>5678 Creative Studio</title>
    <Header />
    <StickyNav href="/5six7eight" bgColor="white" textColor="black" title="5678 Creative Studio" itemArr={ itemLink }/>
    <div className="scrollspy text-black" id="fsse"
    data-bs-spy="scroll"
    data-bs-target="#stickynav"
    data-bs-offset="64">
      <div className="px-4 mt-5" id="top">
      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="text-center">
          <h1 className="display-title fw-700 pb-3 mb-4" lang="en">5678 Creative Studio</h1>
          <img src={ mockup } width="98%" />
        </div>
      </AnimationOnScroll>
      <div className="text-start mx-lg-15p">
        <AnimationOnScroll animateIn="animate__fadeIn">
          <p className="fs-1 fw-700 mt-5"><span className="fc-6DA662">간편한 수업 진행 · 관리</span><br></br>
          최적화.</p>
          <p className="fs-5 fw-600 w-md-60 lh-base pe-lg-5">
          <span lang="en">5678 Creative Studio</span>는 <span lang="en">5678</span>에서 수업을 진행하는 안무가들이 걱정없이 
          수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공하는 <span lang="en">B2B</span> 서비스 입니다.
          수업등록, 결제 등 복잡한 과정을 한번에 관리하고 오프라인 수업을 진행하는 듯한 인터페이스와 다양한 기능으로 
          수강생들에게 더 나은 수업을 제공해보세요.
          </p>
          <a href='https://xd.adobe.com/view/ef4f9845-4f16-40cf-bb42-6d9ef230fe2f-ba1c/?fullscreen'  target="_blank">
            <button
            className="fs-5 button-text mb-5 fw-700 p-0 fc-6DA662">프로토타입 보기 
            <span lang="en" className="fw-400 fc-6DA662"> 􀆊</span>
            </button>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn">
          <Flush
            team="PO, 2 UI Designers, 2 Front-End Developers, 2 Back-End Developers, Marketer, Content Producer"
            contribute="Overall 80% | UX Design 80%"
            role="Product Owner / Project Owner"
            roleDscrpt="신규 서비스 기획, 시장 조사, 사용자 리서치, 사용자 페르소나 / 저니 맵 제작, 브랜딩 구축, 와이어프레임, 플로우 차트, 스토리보드 등 UX 구축, 제품 프로토타이핑, 사용자 테스트, 음원 제휴 관련 업무, 마케터 & 콘텐츠 프로듀서와 마케팅 방향 구축, 스크럼팀 운영 / 리드"
            softwares={ sftwrList }>
            </Flush>
        </AnimationOnScroll>
        </div>

      </div>
      <div className="px-4 pt-5 mt-5 mx-lg-15p" id="background">
        <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="text-start">
            <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Background</p>
            <p className="fw-section fw-700">증가하는 수요.<br></br>불완전한 환경.</p>
            <Row xs={1} lg={2} className="g-4 mt-3">
                <Col>
                    <Card className="p-5 h-100 bg-F5F5F7 text-start r-24">
                        <Card.Title className="fw-700 mb-3">온라인 수업 수요 증가.</Card.Title>
                        <Card.Img variant="top" src={ background1 } className="pb-4 mx-auto my-2 w-50"/>
                        <Card.Text className="fw-500 mt-3">코로나 19 이후 2040세대 10명 중 6명은 온라인 수업 
                        서비스를 이용한 경험이 있으며 연령대가 낮아 질수록 수강률은 약 70% 이상으로 증가했습니다. 
                        외국어, 자격증, 취미 및 자기계발 등 다양한 분야의 수업을 수강했습니다. </Card.Text>
                    </Card>
                </Col>
                <Col>
                    <Card className="p-5 h-100 bg-F5F5F7 text-start r-24">
                        <Card.Title className="fw-700 mb-3">번거로운 과정.</Card.Title>
                        <Card.Img variant="top" src={ background2 } className="pb-4 mx-auto my-2"/>
                        <Card.Text className="fw-500 mt-3">높은 수강률만큼 다양한 수업이 온라인으로 진행되고 있는데, 
                        그 중 댄스 수업도 한 부분을 차지하고 있습니다. 그러나 댄서들에게 그 과정은 너무 번거롭습니다. 
                        수업을 진행하는 안무가 뿐만 아니라 수업을 듣는 수강생들 또한 여러 서비스를 번갈아 가며 
                        사용하는 번거로운 과정을 수업을 들을 때마다 반복해야합니다.</Card.Text>
                    </Card>
                </Col>
            </Row>
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Row xs={1} lg={2} className="bg-F5F5F7 text-start r-24 mt-4 mx-0 py-5">
              <Col className="ps-lg-0 px-5">
                <Card.Title className="fw-700 mb-3 d-lg-none">부족한 환경.</Card.Title>
                <img src={ background3 } width="80%" />
              </Col>
              <Col className="my-auto w-lg-45 px-5">
                  <Card.Title className="fw-700 mb-3 d-none d-lg-block">부족한 환경.</Card.Title>
                  <Card.Text className="fw-500 mt-3">현재 온라인 라이브 댄스 수업을 진행하는 안무가들은 많아졌지만 
                  그에 맞는 환경은 구축돼있지 안습니다. 상반신 위주 인터페이스의 그룹 미팅 서비스는 전신 촬영해야하는 
                  댄스 수업에 적합하지 않고  수강생들과 소통하고 에너지를 나누기 어렵습니다.</Card.Text>
              </Col>
            </Row>
          </AnimationOnScroll>
        </AnimationOnScroll>
      </div>
      <div className="mx-lg-15p px-4 pt-5 mt-5 text-start" id="process">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start" id="process-1">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Process ①</p>
              <p className="fw-section fw-700">상위 기획</p>
              <div className="mt-2" id="personas">
                <p className="fw-600 mb-5">
                  약 100여명의 원밀리언 댄스 스튜디오 수강생들의 설문조사<br></br>
                  인기 안무가와 이제 막 계약을 한 신입 안무가 총 8인과 수강생 중 5인과 1:1 인터뷰 진행<br></br>
                  타겟 사용자에 대한 정보와 리서치 내용을 토대로 안무가와 수강생 페르소나 제작
                  </p>
                <AnimationOnScroll animateIn="animate__fadeInLeft" id="persona-jj">
                  <Row xs={1} lg={2} className="g-0 mt-4">
                    <Col lg={8}>
                      <div className="text-start">
                        <img src={ persona1 } width="100%" />
                      </div>
                    </Col>
                  </Row>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRight" id="persona-liz">
                  <Row xs={1} lg={2} className="g-0 mt-2 mt-lg-n20">
                    <Col lg={4}></Col>
                    <Col lg={8}>
                      <div className="text-end">
                        <img src={ persona2 } width="100%" />
                      </div>
                    </Col>
                  </Row>
                </AnimationOnScroll>
              </div>
              <div className="mt-2 mb-5" id="goals">
              <AnimationOnScroll animateIn="animate__fadeIn">
            <p className="fw-600 mb-5">
              사용자 리서치 결과를 팀원들과 공유한 후, 서비스의 목표와 기능적 하위 목표 정의<br></br>
              아이디에이션 후, 주요 플로우와 필요한 기능 전부 리스트업
            </p>
            <div className="w-lg-55 mx-auto">
              <img src={ goalFlow } width="100%" />
            </div>
                <Row xs={1} lg={3} className="g-4 mt-3">
                  <Col>
                      <Card className="bg-F5F5F7 px-4 py-4 h-100 border-0 text-start">
                          <Card.Img variant="top" src={ goal1 } className="pb-3 mx-1" id="icon"/>
                          <Card.Body className="text-start p-0 mx-1">
                          <Card.Title className="fw-700 mb-3">쉽고 직관적인 디자인.</Card.Title>
                          <Card.Text className="fw-500">수업 등록부터 수업이 끝날 때까지 수업을 쉽게 관리할 수 있는 
                          직관적이고 스타일리시한 인터페이스</Card.Text>
                          </Card.Body>
                      </Card>
                  </Col>
                  <Col>
                      <Card className="bg-F5F5F7 px-4 py-4 h-100 border-0 text-start">
                          <Card.Img variant="top" src={ goal2 } className="pb-3 mx-1" id="icon"/>
                          <Card.Body className="text-start p-0 mx-1">
                          <Card.Title className="fw-700 mb-3">온라인 클래스 경험 극대화.</Card.Title>
                          <Card.Text className="fw-500">안무가/수강/환경이 유기적으로 연결 UX 설계로 
                          온라인 댄스 수업에 최적화된 사용성</Card.Text>
                          </Card.Body>
                      </Card>
                  </Col>
                  <Col>
                      <Card className="bg-F5F5F7 px-4 py-4 h-100 border-0 text-start">
                          <Card.Img variant="top" src={ goal3 } className="pb-3 mx-1" id="icon"/>
                          <Card.Body className="text-start p-0 mx-1">
                          <Card.Title className="fw-700 mb-3">현장의 분위기를 그대로.</Card.Title>
                          <Card.Text className="fw-500">현장 수업의 에너지를 그대로 느낄 수 있게 다양한 재미 요소를 
                          반영한 기능 제공</Card.Text>
                          </Card.Body>
                      </Card>
                  </Col>
                </Row>
          </AnimationOnScroll>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" id="process-2">
                  <Row xs={1} lg={2} className="g-0 mt-4">
                    <Col lg={8}>
                      <div className="text-start">
                        <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Process ②</p>
                        <p className="fw-section fw-700">정책 수립</p>
                        <div className="mt-2">
                          <p className="fw-600 mb-5">
                            경영팀과 수익 모델에 대해 논의했을때, 유료화가 불가피하다고 판단해<br></br>
                            타 서비스의 유료 서비스 리서치 후, 유료화 모델 구축<br></br>
                            출시 국가의 법률과 타 서비스와 인앱 결제 등 여러 정책 리서치 후,<br></br>
                            <span lang="en">5six7eight</span> 커뮤니티 가이드라인 정책 수립
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" id="process-3">
            <Row xs={1} lg={2} className="g-0 mt-2">
              <Col lg={4}></Col>
              <Col lg={8}>
                <div className="text-end">
                  <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Process ➂</p>
                  <p className="fw-section fw-700" lang="en">Wireframe & Storyboard</p>
                  <div className="mt-2">
                    <p className="fw-600 mb-3">
                    정해진 기능에 대한 전체 와이어 프레임을 러프하게 작성<br></br>
                    와이어 프레임을 토대로 개발자들과 기능별 가능성 논의 후, 와이어 프레임 수정<br></br>
                    스프린트별 목표에 따라 화면 단위로 스토리보드(화면설계서)를 피그마로 작성 및 공유<br></br>
                    진행 상황에 따른 기획 변경에 따라 와이어 프레임과 스토리보드 업데이트
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="g-3 mb-5">
              <Col lg={6}>
                <img src={ws1} width="100%" className="rounded-3 img-shadow mb-2"/> 
                <small className="fw-500 fc-gray">와이어프레임 & 스토리보드 - 기능별 화면끼리의 플로우</small>
              </Col>
              <Col lg={6}>
                <img src={ws2} width="100%" className="rounded-3 img-shadow mb-2"/> 
                <small className="fw-500 fc-gray">와이어프레임 & 스토리보드 - 화면별 요소 설계</small>
              </Col>
            </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" id="process-4">
                  <Row xs={1} lg={2} className="g-0 mt-4">
                    <Col lg={8}>
                      <div className="text-start">
                        <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Process ➃</p>
                        <p className="fw-section fw-700" lang="en">UX/UI Design</p>
                        <div className="mt-2">
                          <p className="fw-600 mb-5">
                            5six7eight 브랜딩에 따라 시스템 디자인 방향 결정<br></br>
                            와이어프레임을 기반으로 사용자가 쉽게 사용할 수 있느 정보 구조 설계<br></br>
                            화면별 UX 라이팅 및 콘텐츠 구성과 위계(Hierarchy)를 분류<br></br>
                            시스템 디자인 방향과 콘텐츠 구성, 위계에 따라 UI 에셋 디자인<br></br>
                            추후 일관적인 디자인을 위해 UX/UI 디자인 시스템 수립
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" id="process-5">
            <Row xs={1} lg={2} className="g-0 mt-2">
              <Col lg={4}></Col>
              <Col lg={8}>
                <div className="text-end">
                  <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Process ➄</p>
                  <p className="fw-section fw-700">유저 테스트</p>
                  <div className="mt-2">
                    <p className="fw-600 mb-3">
                      실제 사용자 테스트에 들어가기 전에 사내 테스트 진행<br></br>
                      시스템의 학습 용이성과 발생할 수 있는 오류를 밝히기 위해<br></br>
                      인지적 사찰법(Cognitive Walkthrough)기법을 사용<br></br>
                      화상으로 진행해 테스터의 실제 화면을 녹화해 행동을 분석하고<br></br>
                      질문지를 작성해 포스트 테스트 리포트 작성 후 개선 방향 탐구
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="g-3">
              <Col lg={6}>
                <img src={ut1} width="100%" className="rounded-3 img-shadow mb-2"/> 
                <small className="fw-500 fc-gray">사용자 테스트 - 테스트 중 행동 분석을 위해 사용자 화면 녹화</small>
              </Col>
              <Col lg={6}>
                <img src={ut2} width="100%" className="rounded-3 img-shadow mb-2"/> 
                <small className="fw-500 fc-gray">사용자 테스트 - 포스트 테스트 리포트 작성</small>
              </Col>
            </Row>
          </AnimationOnScroll>
      </div>
      <div id="feature">
        <div className="px-4 pt-5 mt-5 mx-lg-15p">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Features</p>
              <p className="fw-section w-md-60 fw-700">최고로.<br></br>쉽게. 예쁘게.</p>
            </div>
          </AnimationOnScroll>
        </div>
        <div className="pt-5 mb-4 bg-grad-ff-fa">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Row xs={1} lg={2} className="g-0 mt-4">
              <Col lg={6}>
                <div className="text-start">
                  <img src={ feature1 } width="95%" />
                </div>
              </Col>
              <Col lg={4} className="my-auto">
                <div className="text-start mx-5">
                  <p className="h3 fw-700 fc-6DA662 ms-3 mb-3">대시보드</p>
                  <p className="h5 ms-3 fw-600">
                  바로 내 수업 일정과 성장을 한눈에 확인<br></br>
                  춤과 음악은 뗄 수 없는 사이. 오늘의 음악 추천<br></br>
                  계속해서 업데이트되는 5678 소식을 빠르게 확인</p>
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </div>
        <div className="pt-5 mb-4 bg-grad-ff-fa float-lg-right">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Row xs={1} lg={2} className="g-0 mt-4">
              <Col lg={2}></Col>
              <Col lg={4} className="my-auto">
                <div className="text-start mx-5">
                  <p className="h3 fw-700 fc-6DA662 ms-3 mb-3">수업 관리</p>
                    <p className="h5 ms-3 fw-600">
                    <span lang="en">VOD</span>와 라이브를 한 페이지에서 관리<br></br>
                    원하는 정보만 볼 수 있게 필터링과 정렬 기능<br></br>
                    <span lang="en">YouTube</span>에 익숙한 댄서들을 위해 유사한 <span lang="en">UI</span>에 브랜딩을 한 숟갈
                    </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-end">
                  <img src={ feature2 } width="95%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </div>
        <div className="pt-5 mb-4 bg-grad-ff-fa">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Row xs={1} lg={2} className="g-0 mt-4">
              <Col lg={6}>
                <div className="text-start">
                  <img src={ feature3 } width="95%" />
                </div>
              </Col>
              <Col lg={4} className="my-auto">
                <div className="text-start mx-5">
                  <p className="h3 fw-700 fc-6DA662 ms-3">수업 등록</p>
                    <p className="h5 ms-3 fw-600">
                      내 수업은 내가 원하는대로<br></br>
                      기본 정보은 물론, 5six7eight 커뮤니티 검색 정확도를 높이는 음악, 레벨 등 입력<br></br>
                      글로벌화를 위해 수업 언어와 자막까지 등록</p>
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </div>
        <div className="pt-5 mb-4 bg-grad-ff-fa float-lg-right">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Row xs={1} lg={2} className="g-0 mt-4">
              <Col lg={2}></Col>
              <Col lg={4} className="my-auto">
                <div className="text-start mx-5">
                  <p className="h3 fw-700 fc-6DA662 ms-3">정산 관리</p>
                    <p className="h5 ms-3 fw-600">
                    영감은 돈에서 나온다. 정산은 확실하게.<br></br>
                    어려운 내용은 친절한 <span lang="en">UX</span>라이팅으로 정리<br></br>
                    복잡한 정산 과정은 서비스팀의 몫, 안무가는 은행 정보만 입력하면 매달 자동 정산</p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-end">
                  <img src={ feature4 } width="95%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="mt-5" id="review">
      <div className="px-4 py-5 mx-lg-15p">
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="text-start">
            <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Review</p>
            <p className="fw-section w-md-60 fw-700">프로젝트를 마무리하며</p>
          </div>
          <div className="mb-5">
            <p className="h3 fw-700">애자일하게.</p>
            <p className="mt-2 mb-3 mt-lg-2 mb-lg-4">프로덕트 팀이 이제 막 꾸려지기 시작했을 시기에 입사해 체계가 전혀 잡혀있지 않았다. 
              CTO님과 함께 그 체계를 만들어 가는 과정 또한 나의 몫이였다 처음 서비스 기획/디자인과 동시에 운영 
              체계를 만들어 나가는 일은 고통스러웠다. 매일 디자이너, 개발자들과 데일리 회의를 진행해 서로의 진행 
              상황을 투명하게 공개하고 매 스프린트 종료 직전 다음 스프린트의 목표를 설정하고 업무를 배분하는 일은 
              서로에게 불편한 상황이었다. 처음에는 감시받는 느낌도 들고 수평적인 구조에서 내가 상위에서 팀원들에게 
              일을 시키는 느낌도 들어 불편했다. 모두가 진행 상황을 솔직하게 공개하고 서로 조정해 나가면서 업무를 더 
              효율적으로 진행할 수 있게 되었고 팀원들이 나를 믿고 조금은 강압적으로 느껴질 수도 있는 상황을 괜찮다고 
              응원해줌과 함께 스프린트 회고 시 적극적인 피드백을 주어서 스프린트마다 조금씩 발전할 수 있었다.
            </p>
            <p className="my-3 my-lg-4">애자일을 운영하면서 가장 좋았던 점은 데일리 스크럼 회의였다. 모두가 피곤한 아침 시간 회의에서 간단한 
              스몰토크와 커피로 잠을 깨고 말랑말랑한 분위기에서 진행 상황을 공유해 나를 포함한 모두가 더욱 솔직하게 
              상황과 의견을 전달할 수 있었기에 힘든 스프린트를 성공적으로 진행할 수 있었다는 점에서 좋았다. 또한 
              동료들의 업무 속도, 스타일을 파악할 수 있어서 유용한 시간이었다. 처음 두 스프린트는 동료들의 업무 
              속도, 능력을 파악하지 못해 과한 계획을 세워 기간 내에 목표를 달성하지 못했다. 아마 데일리 스크럼 
              회의를 단순한 업무 보고 정도로만 여긴 탓일 것이다. 처음 두 스프린트를 제대로 완료하지 못한 후에는 
              데일리 스크럼 회의에서 팀원들은 발표에 집중해 각자의 업무의 속도, 스타일 등을 파악해 업무를 나눴다. 
              그러다 보니 일정 산정이 쉬워지고 상부에 보고하거나 그들의 요청 사항을 더 지혜롭게 처리할 수 있게 
              되었다.
            </p>
          </div>
          <div>
            <p className="h3 fw-700">아쉬움. 그리고 퇴사와 성장</p>
            <p className="mt-2 mb-3 mt-lg-2 mb-lg-4">5six7eight은 처음 기획부터 내가 정말 애정을 많이 쏟은 
            프로젝트이다. 원밀리언의 1호 PO로 가장 큰 프로젝트를 맡게 되어 부담도 걱정도 많았다. 개발자들을 제외하고는 
            IT 사업에 문외한 사람들과 일하게 되어 소통에서 많은 어려움이 있었고 댄스씬의 문화를 이해하는 과정 또한 
            어려웠다. 댄스씬의 문화는 여러 리서치와 안무가님들과의 대화를 통해 배울 수 있었지만, 상부에 보고하는 일은 
            정말 벽에 대고 이야기하는 느낌이었다. 특히 사용자 리서치같이 상위 기획에 필요한 과정이 필요한 이유를 
            설득하기까지 정말 오래 걸렸다. 그 과정에서 시간이 필요 이상으로 걸렸다. 하지만 결국 설득에 성공했고 그를 
            토대로 아이디어를 프로덕트로 탄생시키는 데에 성공해 1차 사용자 테스트까지 성공적으로 마무리를 했다. 
            애정을 많이 쏟은 만큼 정식 출시를 하지 못하고 퇴사하게 되어 아쉬움이 남는 것은 어쩔 수 없다. 끊임없이 
            성장하고 싶어 하는 내가 이 회사에 남아 발전할 수 있을까를 생각해봤을 때, 그만큼의 메리트가 없다는 생각이 
            들어 과감히 퇴사를 선택했다. 그렇다고 배움이 없었던 것은 아니다. 스프린트라던가 팀 리딩 이라는 값진 경험을 
            통한 나의 리더쉽 증명했고 디자인과 기획 모두 잘하는 멋진 프로덕트 디자이너로 성장할 수 있겠다는 확신이 
            생겼다. 무엇보다 퇴사 이야기를 처음 팀원들에게 꺼냈을 때, "진아님이랑 같이 계속 일하고 싶어요.", 
            "회사에 직급이 있다면 진아님이 팀장이면 좋겠다." 등 들었을 때, 어쩌면 입에 발린 말들일 지도 모르지만 내가 
            좋아하는 프로덕트 디자인과 기획일에 대한 자기 확신이 들었다. 
            </p>
          </div>
          </AnimationOnScroll>
      </div>
      </div>
      <div className="pt-4 pb-4 mx-0 mb-5 text-center bg-white" id="case-study">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <h6 className="fw-600 mt-5" lang="en">Case Study</h6>
        <h1 className="display-3 fw-700 pb-3 blue-gradient">이제는 증명할 시간.</h1>
        <p className="mx-5 mb-0 fw-600">아래에서 마음에 드는 것을 골라 탐구해보세요.</p>
        <p className="mx-5 fw-600">신규 서비스 기획/디자인부터 스크럼 팀 리드까지 저의 작업 과정, 스타일 등을 심층 탐구 할 수 있습니다.</p>
        <Row xs={1} md={3} className="g-4 mt-4">
        <CardCol link="/5six7eight" imgSrc={ card5678 } title="5six7eight Creative Studio"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        <CardCol link="/1m-workshop" imgSrc={ card1mws } title="1M Workshop"
        text="기존의 워크샵 예약 FLOW를 보완한 어떤 워크샵 이벤트에서도 사용할 수 있는 Universal All-in-1 웹사이트를 소개합니다." />
        <CardCol link="/1m-homedance" imgSrc={ card1mhd } title="1M HomeDance"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        </Row>
        </AnimationOnScroll>
      </div>
    </div>
    <Footer bgColor="black" textColor="white" />
  </main>
  )
}

export default Project1
